<template>
    <div class="error-page">
        <div class="error-code">5<span>0</span>5</div>
        <div class="error-desc">系统发生错误，客户号不能为空.</div>
<!--        <div class="error-handle">-->
<!--            <router-link to="/login">-->
<!--                <el-button type="primary" size="large">返回登陆页</el-button>-->
<!--            </router-link>-->
<!--            <el-button class="error-btn" type="primary" size="large" @click="goBack">返回上一页</el-button>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    methods: {
        goBack() {
            this.$router.go(-1);
        }
    }
}
</script>


<style scoped>
.error-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #f3f3f3;
    box-sizing: border-box;
}

.error-code {
    line-height: 1;
    font-size: 250px;
    font-weight: bolder;
    color: #2d8cf0;
}

.error-code span {
    color: #00a854;
}

.error-desc {
    font-size: 30px;
    color: #777;
}

.error-qq {
    font-size: 20px;
    color: #777;
}

.error-handle {
    margin-top: 30px;
    padding-bottom: 200px;
}

.error-btn {
    margin-left: 10px;
}
</style>
